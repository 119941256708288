<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 pr-sm-1 p-0 py-1">
        <base-card card="flex-fill" header="bg-dark">
          <template #header>بيانات التأمين</template>
          <template #body>
            <div class="container">
              <div class="row" v-if="!!user">
                <div class="col-sm-6">
                  <p>
                    <span>الإسم الكامل:</span>&nbsp;<span
                      class="text-secondary"
                  >{{ user.name }}</span
                  >
                  </p>
                </div>
                <div class="col-sm-6">
                  <p>
                    <span>رقم الهاتف:</span>&nbsp;<span class="text-secondary">
                      {{ user.mobile }}</span
                  >
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <p>
                    <span>الحد الأعلى للمزاودة:</span>&nbsp;<span
                      class="text-secondary"
                  >{{ !!insurance ? insurance.max : "غير محدد" }}</span
                  >
                  </p>
                </div>
                <div class="col-sm-6">
                  <p>
                    <span>مبلغ التأمين:</span>&nbsp;<span
                      class="text-secondary"
                  >{{ !!insurance ? insurance.price : "غير محدد" }}</span
                  >
                  </p>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-sm-6">
                  <modal-btn
                      target="#add_ins_price"
                      btn-class="btn-sm btn-red"
                      :disabled="!canAddInsPrice"
                  >
                    رفع سقف التأمين
                  </modal-btn>
                  <button class="btn btn-info btn-sm rounded-0 ml-2" @click="refresh">
                    تحديث التأمين
                  </button>

                  <p v-if="!canAddInsPrice">
                    <small class="font-weight-bolder">
                      <label>لإضافة لوصل التأمين يرجى</label>
                      <router-link to="/contact" class="link m-1"
                      >التواصل
                      </router-link
                      >
                      <label>مع الإدارة</label>
                    </small>
                  </p>
                  <confirm-model
                      v-if="canAddInsPrice"
                      target-id="add_ins_price"
                      title="إضافة وصل تأمين"
                      header-bg="bg-red"
                      confirm-text="إضافة"
                      @confirmed="clickById('add_ins_image_btn')"
                  >
                    <div class="w-100 d-flex justify-content-center">
                      <form
                          @submit.prevent="storeInsImage"
                          id="add_ins_image_form"
                      >
                        <form-image id="ins_image" ref="ins_image"></form-image>
                        <button
                            type="submit"
                            hidden
                            id="add_ins_image_btn"
                        ></button>
                      </form>
                    </div>
                  </confirm-model>
                </div>
              </div>
            </div>
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import files_mixin from "@/mixins/files_mixin";
import Storage from "@/common/Storage";
import FormImage from "@/components/Forms/FormImage";
import store from "@/store";

export default {
  components: {FormImage, ConfirmModel, ModalBtn, BaseCard},
  mixins: [files_mixin],
  data() {
    return {
      insurances: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.authorized;
    },
    canAddInsPrice() {
      return this.$store.getters.addInsurance;
    },
    insurance() {
      return this.$store.getters.hasInsurance ? this.user.insurance : "";
    },
  },
  methods: {
    async storeInsImage(e) {
      const uploadedFileName = this.getFileName("ins_image");
      const storedFileName = Storage.get("lastUploadedFileName");
      this.lastUploadedFileName = !!this.lastUploadedFileName
          ? this.lastUploadedFileName
          : !!storedFileName
              ? storedFileName.data
              : "";
      if (!!uploadedFileName) {
        if (uploadedFileName === this.lastUploadedFileName) {
          http.popupMessage("error", messages.imageUploaded);
        } else {
          const formData = new FormData();
          formData.append("image", this.file);
          const response = await http.send("user/insurance-images", new FormData(e.target), {
            headers: {'Content-Type': 'multipart/form-data'}
          });
          if (http.responseAccepted(response)) {
            this.lastUploadedFileName = uploadedFileName;
            Storage.set("lastUploadedFileName", this.lastUploadedFileName, 3);
            http.popupMessage("success", messages.imageSent);
            this.hideModal("#add_ins_price");
            this.$refs.ins_image.clearInputFile("ins_image");

            const authorized = this.user
            authorized.add_insurance = 'n'
            this.$store.dispatch('setAuthorized', authorized)
          }
        }
      } else {
        http.popupMessage("error", messages.shouldSelectImg);
      }
    },
    async refresh() {
      const response = await http.send('my-insurance', {_method: 'GET'})

      if (http.responseAccepted(response, 'insurance')) {
        const authorized = this.user
        authorized.insurance = response.data.insurance
        await store.dispatch("setAuthorized", authorized);
      }
    }
  },
};
</script>
